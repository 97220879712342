$component-name: block-category;

.#{$component-name} {
  #category-description {
    font-size: 1rem;
    color: var(--bs-gray-800);
  }
}


.page-category, .page-module-pm_advancedsearch4-searchresults {
  .block-category {
    .category-cover {
      @include media-breakpoint-down(lg) {
        min-height: 38vw;
      }
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position: 50% 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .swiper-container .swiper-wrapper {
    figure {
      img {
        width: auto;
      }
      figcaption {
        color: $primary;
      }
    }
  }

  .PM_ASForm {
    .facet__title i {
      font-size: 1.25rem;
    }
  }
  .PM_ASSelectionsRemoveLink {
    display: flex;
    align-items: center;
    color: $gray-500;
    font-weight: 400;
    font-size: .875rem;
    margin-right: .625rem;
    
    span {
      line-height: 1;
      font-size: 1.25rem;
      margin-right: .125rem;
    }
  }

  .products-selection {
    .sort-by-row {
      font-weight: 400;
    }

    .products-sort-order {
      .btn {
        width: auto; 
        min-width: 10rem;
        border-color: $black;
      } 
      .btn, .dropdown-menu {
        &.show, &:hover, &:active {
          border-color: $black;
        }
      }
      .dropdown-menu {
        position: absolute;
        margin: 0px!important;
        inset: 0px auto auto 0px!important;
        transform: translate(0px, 50px)!important;
      }
    } 
  }

  .breadcrumb__wrapper {
    margin-bottom: 0;
    .container {
      padding: 0;
    }
  }

  .page-title-section {
    color: $black;
  }
}


;@import "sass-embedded-legacy-load-done:2110";