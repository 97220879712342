$component-name: header;

.#{$component-name} {
  --header-bg: #{$header-bg};
  --header-top-bg: #{$header-top-bg};
  --header-color: #{$header-color};
  --header-top-color: #{$header-top-color};

  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  max-width: 100vw;
  background-color: var(--header-bg);

  a:not(.dropdown-item):not(.btn) {
    color: var(--header-color);

    &:hover {
      &,
      i {
        color: var(--bs-primary);
      }
    }
  }
}


;@import "sass-embedded-legacy-load-done:2070";