$component-name: checkout__steps;

.#{$component-name} {

  &__number {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
    color: var(--bs-white);
    background: var(--bs-gray-300);
    border-radius: 100%;
  }

  &__item {
    position: relative;

    &::after,
    &::before {
      position: absolute;
      top: 0.75rem;
      z-index: 0;
      width: 50%;
      height: 2px;
      content: "";
      background: var(--bs-gray-300);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &:first-of-type {
      &::before {
        display: none;
      }
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  &__text {
    line-height: 1;
  }

  &--current {
    .#{$component-name}__number {
      background: var(--bs-gray-900);
    }

    .#{$component-name}__text {
      color: var(--bs-gray-900);
    }

    &::before {
      background: var(--bs-success);
    }
  }

  &--success {
    .#{$component-name}__number {
      background: var(--bs-success);
    }

    .#{$component-name}__text {
      color: var(--bs-success);
    }

    &::before {
      background: var(--bs-success);
    }

    &::after {
      background: var(--bs-success);
    }
  }

  &__list {
    width: 100%;
    max-width: 45rem;
    margin: auto;
  }

  &__mobile {
    width: 100%;
  }

  /* Do not assign any styles to active tab */
  .nav-tabs .checkout__steps--success .nav-link.active {
    color: var(--bs-success);
  }

  .nav-tabs .checkout__steps--current .nav-link.active {
    color: var(--bs-gray-900);
  }
}

.carrier__extra-content-wrapper {
  max-height: inherit!important;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  
  .colissimo-pickup-point-address {
    margin-top: 1rem;
  }

  #colissimo-pickup-point-address-selected {
    border: 2px solid #b9975b;
  }
}
;@import "sass-embedded-legacy-load-done:2038";