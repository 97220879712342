$component-name: address;

.#{$component-name} {
  border-radius: 0.5rem;
  border: .0625rem solid $gray-200;

  &__content {
    margin: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: var(--bs-gray-800);
  }

  &__actions {
    a {
      text-decoration: underline;
      text-underline-position: under;
    }
  }

  &__alias {
    margin-bottom: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: var(--bs-gray-800);
  }

  .card-footer {
    padding: 0 0.5rem;
    background: none;
  }

  &__edit,
  &__delete {
    display: inline-block;
    padding: 1rem 0.5rem;
    color: var(--bs-gray-800);
    text-decoration: underline;
  }

  &__delete {
    color: var(--bs-danger);
  }
}

;@import "sass-embedded-legacy-load-done:2137";