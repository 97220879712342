.rich-text {
  line-height: 1.5;

  table {
    @extend .table;
  }
  
  > h1:first-child,
  > h2:first-child,
  > h3:first-child,
  > h4:first-child,
  > h5:first-child,
  > h6:first-child {
    margin-top: 0;
  }

  p,
  ul {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  ul {
    padding-left: 1.2rem;
    list-style: disc;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

;@import "sass-embedded-legacy-load-done:2025";