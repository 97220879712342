$component-name: cart;

.#{$component-name} {
  &-container {
    border-top: .125rem solid $primary;
  }
  
  &-overview {
    &:focus-visible {
      outline: 0;
    }
  }

  &-summary {
    padding: 1rem 2rem 2rem;
    @media screen and (max-width: 1201px) {
      padding: 1rem;
    }

    .card-footer {
      padding: 0;
      padding-top: 1rem;
    }

    &,
    .card-footer {
      background-color: $secondary;
    }

    &__container {
      .card-header,
      .card-footer {
        background-color: var(--bs-white);

        i {
          color: inherit;
        }
      }
    }

    &__products {
      margin-bottom: 0.75rem;
      border-bottom: 1px solid $light;

      > p {
        padding: 1rem 0;
        margin-bottom: 0;

        &:first-of-type {
          padding-top: 0.5rem;
          border-bottom: 1px solid $light;
        }
      }

      .cart-summary__product {
        &__current {
          margin-bottom: 0.5rem;
        }

        &__body {
          .label,
          .value {
            font-size: .875rem;
            font-weight: 400;
            color: var(--bs-gray-800);
          }

          .product-line-info {
            margin: 0;
          }

          .product-name {
            a {
              font-weight: 600;
              color: var(--bs-gray-800);

              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
    }

    &__show {
      font-weight: 500;

      i {
        color: var(--bs-primary);
      }

      span {
        vertical-align: middle;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    &__totals {
      padding-top: 1rem;
      margin-top: 0.75rem;
      border-top: 1px solid $light;

      span {
        font-weight: 700;
      }
    }

    &__line,
    &__total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;

      span {
        font-size: 1rem;

        &.value {
          color: var(--bs-gray-800);
        }
      }
    }
    &__value {
      font-weight: 600;
    }
  }

  &-detailed {
    &__actions {
      padding-top: 0;

      .btn-primary {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__item {
    border-bottom: 1px solid $light;
    
    .product-line {
      &__title {
        width: 100%;
        font-weight: 600;
        color: var(--bs-gray-800);
        font-family: $font-family-highlight;
      }

      img {
        border: 1px solid $light;
      }

      &__item {
        display: block;
        margin-bottom: 0.5rem;
      }

      &__current {
        span {
          font-weight: 600;
        }
      }

      &__regular {
        color: var(--bs-gray-600);
        text-decoration: line-through;
      }

      &__price {
        font-weight: 600;
      }

      &__basic {
        .badge {
          padding: 0.25rem;
        }
      }

      &__informations {
        @include media-breakpoint-down(sm) {
          justify-content: flex-start !important;
          margin-top: .75rem;
        }
      }

      &__actions {
        a {
          display: inline-block;
          padding: 0.5rem;
          margin-left: -0.5rem;

          &::after {
            display: block;
            width: 100%;
            height: 1px;
            content: "";
            background: var(--bs-primary);
            transition: 0.25s ease-out;
          }

          &:hover {
            &::after {
              opacity: 0;
            }
          }
        }
      }

      hr {
        margin-top: 0.75rem;
      }
    }
  }
}


.page-cart {
  .remove-from-cart {
    color: $primary;
    text-decoration: underline;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .breadcrumb__wrapper {
    display: none;
  }

  .product-line__info {
    margin-bottom: 0;

    .label, .value {
      font-size: .875rem;
    }
  }

  .cart-summary .accordion-button {
    justify-content: center;
    &:after {
      display: none;
    }
  }

  .swiper-products {
    padding-left: 0;
  }

  .section-title {
    margin-right: 0;
    margin-left: 0;
  }

  .swiper.swiper-products {
    padding-left: 0;
    padding-right: 0;
    .swiper-slide {
      width: 33.333%;
    }
  }
}

.pm-csoc .product-price-and-shipping {
  // .price {
    margin-left: 0.625rem;
  // }
  .product-miniature__discount-price {
    text-decoration: line-through;
  }
}
  

.checkout-divider {
  border-top: 0.0625rem solid #e9ecef;
}

.payment__definitions {
  padding-top: 0;
  margin-bottom: 1.5rem;
}

.payment__option {
  &:not(:first-of-type) {
    margin-top: 1.25rem;
  }
  img {
    max-height: 1.75rem;
    max-width: 100%;
    height: auto;
    margin-left: 0.5rem;
  }
}

.page-cart,
.page-order {
  .blockreassurance_product {
    margin-top: 1.5rem;
    img {
      margin-bottom: .125rem;
    }
    p {
      font-size: .875rem;
      text-transform: initial;
    }
    div:nth-of-type(4) span {
      font-size: 1rem;
    }
  }
}


;@import "sass-embedded-legacy-load-done:2127";