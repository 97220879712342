$component-name: email-subscription;
$email-input-color: $input-color !default;
$email-input-border-color: $input-border-color !default;

.#{$component-name} {
  position: relative;
  color: $white;
  overflow: hidden;

  & &__content {
    margin: auto;

    &__left {
    }

    &__text {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    &__infos {
      margin-top: 0.25rem;

      p {
        margin: 0;
      }
    }

    &__inputs {
      display: flex;
      align-items: center;

      input[type="email"] {
        border: none;
      }

      .btn {
        min-height: 2.75rem;
        padding: .625rem 1.25rem;
        text-transform: inherit;
      }
    }
  }

  .form-check {
    padding-left: 0;

    .form-check-input {
      float: none;
      margin-left: 0;
      margin-right: .5rem;
    }
    .form-check-label {
      text-align: left;
    }
  }

  & &__label {
    color: $white;
    font-family: $font-family-highlight;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

;@import "sass-embedded-legacy-load-done:2168";