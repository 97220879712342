$component-name: language-selector;

.#{$component-name}__wrapper {
  @include media-breakpoint-down(md) {
    margin-bottom: 1rem;
  }

  select {
    cursor: pointer;
  }
}

;@import "sass-embedded-legacy-load-done:2093";