.pagination {
  margin-bottom: 0;

  .page-link i {
    width: auto;
    height: auto;
    font-size: 1.25rem;
    font-weight: 400;
    color: $primary;
  }

  &-container {
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

;@import "sass-embedded-legacy-load-done:2023";