.layout-left-column {
  #left-column {
    padding-right: 3rem;
  }
}

#js-active-search-filters {
  order: 1;
  @include media-breakpoint-down(lg) {
    order: 1;
  }
}

.filter-block-wrapper {
  order: 0;
  @include media-breakpoint-down(lg) {
    order: 1;
  }
}

.products-selection {
  @include media-breakpoint-down(lg) {
    order: 2;
  }
}

#js-product-list {
  .product-miniature {
    margin-bottom: 2.5rem;
  }
}

.page-category.layout-full-width {
  .filter-block.row {
    margin-bottom: 1.25rem; 
  }
  .products-selection {
    margin-bottom: 0;
    @include media-breakpoint-down(lg) {
      margin-bottom: 1.25rem;
    }
  }
}

@media screen and (max-width:1400px) {
  .products-selection .pagination-container{
    display: none;
  }
}

.layout-right-column,
.layout-left-column,
.layout-full-width {
  .products-selection {
    margin-bottom: 1.25rem;

    .select-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      // color: $primary;
      font-size: .875rem;
      text-align: left;
      font-weight: 700;
      padding: .875rem 1.25rem;
      border: 1px solid $gray-300;

      i {
        font-size: 1.25rem;
      }
    }

    &s-filters {
      align-items: center;
    }

    .sort-by-row {
      align-items: center;
    }
  }

  .total-products {
    @include media-breakpoint-down(lg) {
      display: none!important;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.listing-header {
  background-color: $black;
  * {
    color: $white;
  }
  h1 {
    color: $white;
  }

  a {
    color: $primary;
  }

  .breadcrumb-item + .breadcrumb-item:before {
    color: #fff;
  }

  .breadcrumb__wrapper {
    margin-bottom: 0;
    > * {
      color: #fff;
      font-weight: 300;
    }
  }
}

.search-filter-toggler {
  display: flex;
  min-width: 9rem;
  font-size: .875rem;
  padding: .875rem 1rem;
  @include media-breakpoint-up(lg) {
    display: none;
  }
  &.icon_svg:not(use) > svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}
;@import "sass-embedded-legacy-load-done:2117";