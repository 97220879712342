.detail {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 4px solid var(--bs-white);

  &__left {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
  }

  &__title {
    font-weight: 700;
  }
}

;@import "sass-embedded-legacy-load-done:2229";