/* stylelint-disable value-keyword-case, order/properties-order, comment-empty-line-before, comment-whitespace-inside */
@use "sass:math";

$component-name: noUi;

.#{$component-name} {
  &-target,
  &-target * {
    box-sizing: border-box;
    /* stylelint-disable */
    touch-action: none;
    /* stylelint-enable */
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: none;
  }

  &-base,
  &-connects {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  // connects elements container
  &-connects {
    z-index: 0;
    overflow: hidden;
    border-radius: $nouislider-connects-border-radius;
  }

  &-connect,
  &-origin {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    transform-origin: 0 0;
    will-change: transform;
    transform-style: preserve-3d;
    transform-style: flat;
    width: 100%;
    height: 100%;
  }

  &-connect {
    width: 100%;
    height: 100%;
    background: $nouislider-connects-background-color;
  }

  /* Offset direction
    */
  &-txt-dir-rtl.#{$component-name}-horizontal .#{$component-name}-origin {
    right: auto;
    left: 0;
  }

  /* Give origins 0 height/width so they don't interfere with clicking the
    * connect elements.
    */
  .#{$component-name}-vertical .#{$component-name}-origin {
    width: 0;
  }

  &-horizontal .#{$component-name}-origin {
    height: 0;
  }

  &-touch-area {
    width: 100%;
    height: 100%;
  }

  &-state-tap .#{$component-name}-connect,
  &-state-tap .#{$component-name}-origin {
    transition: transform 0.3s;
  }

  &-state-drag * {
    /* stylelint-disable */
    cursor: inherit !important;
    /* stylelint-enable */
  }

  /* Slider size and handle placement;
    */
  &-horizontal {
    height: $nouislider-horizontal-height;
    margin: #{math.div($nouislider-horizontal-handler-height, 2)} #{math.div($nouislider-horizontal-handler-width, 2)};
  }

  &-horizontal .#{$component-name}-handle {
    top: -#{math.div(($nouislider-horizontal-handler-height - $nouislider-horizontal-height), 2) + $nouislider-target-border-width};
    /*!/* @noflip */
    right: -#{math.div($nouislider-horizontal-handler-width, 2)};
    width: $nouislider-horizontal-handler-width;
    height: $nouislider-horizontal-handler-height;
  }

  &-vertical {
    width: $nouislider-vertical-width;
    margin: #{math.div($nouislider-vertical-handler-height, 2)} 0;
  }

  &-vertical .#{$component-name}-handle {
    top: -#{math.div($nouislider-vertical-handler-height, 2)};
    right: -#{math.div(($nouislider-horizontal-handler-width - $nouislider-vertical-width), 2) + $nouislider-target-border-width};
    width: $nouislider-vertical-handler-width;
    height: $nouislider-vertical-handler-height;
  }

  /* Styling;
    * Giving the connect element a border radius causes issues with using transform: scale
    */
  &-target {
    position: relative;
    background: $nouislider-target-background-color;
    border: $nouislider-target-border-width solid $nouislider-target-border-color;
    border-radius: $nouislider-target-border-radius;
    box-shadow: $nouislider-target-shadow;
  }

  /* Handles and cursors;
    */
  &-draggable {
    cursor: ew-resize;
  }

  &-vertical .#{$component-name}-draggable {
    cursor: ns-resize;
  }

  &-handle {
    position: absolute;
    cursor: default;
    background: $nouislider-handle-background-color;
    border: $nouislider-handle-border-width solid $nouislider-handle-border-color;
    border-radius: $nouislider-handle-border-radius;
    outline: none;
    box-shadow: $nouislider-handle-shadow;
    backface-visibility: hidden;
  }

  &-active {
    background: $nouislider-handle-active-background-color;
    box-shadow: $nouislider-handle-active-shadow;
  }
}

/* Disabled state;
*/
[disabled] .#{$component-name}-connect {
  background: $nouislider-connects-disabled-background-color;
}

[disabled].#{$component-name}-target,
[disabled].#{$component-name}-handle,
[disabled] .#{$component-name}-handle {
  cursor: not-allowed;
}

;@import "sass-embedded-legacy-load-done:2061";