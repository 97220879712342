.page-cms {
  ul.faq {
    list-style: none;
    margin-bottom: 0;
    padding-left: .75rem;
    li.a {
      display: none;
      padding: 1rem;
    }
    li.q {
      text-transform: uppercase;
      list-style-type: '+';
      padding-inline-start: 1ch;
      color: #B9975B;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .page-cms {
    @extend .container;
  }


//   .breadcrumb__wrapper {
//     display: none;
//   }

  // .elementor-custom-layout > .elementor-container {
  //   @extend .col-xl-8;
  //   @extend .offset-xl-2;
  //   @extend .col-lg-10;
  //   @extend .offset-lg-1;
  //   @extend .col-12;
  // }



  // .elementor-custom-full {
  //   @include media-breakpoint-down(md) {
  //     .elementor-col-50 {
  //       min-height: 100vw;
  //     }
  //   }
  // }
}
;@import "sass-embedded-legacy-load-done:2231";