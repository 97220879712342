$carousel-control-height: 4rem;
$slider-control-bg: var(--bs-white);
$slider-control-radius: 50%;

$slider-title-weight: 800;
$slider-title-size: 3rem;
$slider-subtitle-weight: 700;
$slider-subtitle-size: 1.25rem;
$slider-text-color: var(--bs-gray-800);
$slider-text-spacing: 1rem;

$slider-description-size: 1rem;

$slider-img-height: 100%;

.carousel {
  --slider-img-height: #{$slider-img-height};
  --slider-title-weight: #{$slider-title-weight};
  --slider-subtitle-weight: #{$slider-subtitle-weight};
  --slider-text-color: #{$slider-text-color};
  --slider-control-bg: #{$slider-control-bg};
  --slider-control-height: #{$carousel-control-height};
  --slider-control-radius: #{$slider-control-radius};
}

;@import "sass-embedded-legacy-load-done:1843";