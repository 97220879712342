.page-index {
	.ratio-homeSlider {
		order: 1;
	}
	.displayCustomBanners1  {
		order: 2;
	}
	.new-products,
	.featured-products {
		order: 3;
	}
	.displayHome {
		order: 4;
	}
	.prestablog {
		order: 5;
	}

	#content-wrapper {
	  padding-bottom: 0;
	}

	.section-subtitle {
		margin-bottom: 2.5rem;
	}

	.prestablog {
		.section-subtitle {
			margin-bottom: 1.5rem;
		}
	}

	.product-miniature {
		.product-miniature__availability {
			display: none!important;
		}
	}

	#blog_list_1-7 {
		justify-content: center;
		li.tiers {
	  		width: 25%;
			@media screen and (max-width: 1024px) {
			    width: 50%;
			    &:nth-of-type(3) {
			    	display: none;
			    }
			}
			@media screen and (max-width: 770px) {
			    width: 100%;
			    &:nth-of-type(3) {
			    	display: block;
			    }
			}
		}
	}
}

.swiper.swiper-products,
.swiper.swiper-new-products {
	padding-left: 8.333333%;
	padding-right: 8.333333%;
}
// .swiper.swiper-viewed-products,
.swiper.swiper-products,
.swiper.swiper-new-products {
	overflow: hidden;

	.swiper-wrapper {
		margin-right: -.875rem;
  		margin-left: -.875rem;
	}

	.swiper-slide {
		width: 25%;
		padding: 0 1rem;
		@media screen and (max-width: 1200px) {
			width: 33.333%;
		}
		@include media-breakpoint-down(md) {
			width: 50%;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
}

/* Read More styles */
.custom-text__content {		
	.toggle {
		position: relative;
		display: block;	
		cursor: pointer;
		color: $gray-700;
		padding: .75rem 0;
		text-align: center;
		background: $white;
		border-bottom: .0125rem solid $gray-700;

		&:before {
			content: attr(data-more);
			display: block;
			font-weight: 700;
			font-style: italic;																																																																																																													
			vertical-align: middle;
			font-size: $font-size-base;
		}
		&:after {
			content: " ";
			position: absolute;
			bottom: -.5rem;
			left: calc(50% - .5rem);
			display: block;
			width: 0;
			height: 0;
			border-top: .5rem solid $gray-700;
			border-left: .5rem solid transparent;
			border-right: .5rem solid transparent;
		}
	}
	.mask-text {
		transition: 1s;
		overflow: hidden;
		max-height: 426px;

		&.open {
			max-height: 2500px;
			& + .toggle:before {
				content: attr(data-less);
			}
			& + .toggle:after {
				bottom: 0;
				transform: rotate(180deg);
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:2213";