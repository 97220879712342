$component-name: header-top;

.#{$component-name} {
  background-color: var(--header-top-bg);

  a {
    color: var(--header-top-color);
  }

  &__right {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  &__right,
  &__left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .form-select {
    position: relative;
    z-index: 2;
    background-color: transparent;
    border: none;

    option {
      text-align: left;
      font-size: 0.75rem;
      text-transform: uppercase;
      font-family: $font-family-base;
    }
  }

  &-desktop {
    padding: 1rem 0;
  }

  @include media-breakpoint-down(sm) {
    .container {
      padding: 0;
    }
  }
}

;@import "sass-embedded-legacy-load-done:2071";