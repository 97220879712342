$component-name: carousel;

.#{$component-name} {
  & &-inner {
    height: 100%;
  }

  & &-item {
    align-items: center;
    justify-content: center;
    height: 100%;


    img {
      width: 100%;
      object-fit: cover;
      height: var(--slider-img-height);
    }
  }

  & &-content {
    width: 100%;
    margin-bottom: 0;


    h2, h3, p {
      color: #fff;
    }

    h3 {
      font-family: $font-family-highlight;
      margin-bottom: 1rem;
      font-size: 3.75rem;
      font-weight: 400;

      @media screen and (max-width: 1200px) {
        font-size: 2.25rem;
        line-height: 1.1;
      }
    }

    p {
      font-size: 1rem;
    }

    h2 {
      margin-bottom: 0;
      font-size: 2.1875rem;
      font-family: $font-family-highlight;

      @media screen and (max-width: 1200px) {
        font-size: 1.25rem;
      }
    }
  }

  & &-indicators {
    margin-bottom: 1.25rem;
    
    button {
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 1rem;
      background-color: #fff;
      border: 2px solid #fff;
      @media screen and (max-width: 1200px) {
        width: 0.5rem;
        height: 0.5rem;
      }

      &.active {
        background-color: $primary;
      }
    }
  }

  & &-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  & &-caption {
    top: 50%;
    bottom: inherit;
    max-width: 500px;
    text-align: left;
    transform: translateY(-50%);
  }

  & &-control-prev,
  & &-control-next {
    top: 50%;
    height: 2.75rem;
    width: 2.75rem;
    opacity: 1;
    background: $black;
    transform: translateY(-50%);
  }

  & &-control-prev {
    left: 1rem;
  }

  & &-control-next {
    right: 1rem;
  }

  @include media-breakpoint-down(md) {
    .carousel-control-prev,
    .carousel-control-next {
      height: 2rem;
      width: 2rem;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:2090";