.category-footer-desc {
  background-color: $black;
  
  &-content {
    *:not(a){
      color: #fff;
    }

    ul {
      padding-left: 1.2rem;
      list-style: disc;
    }
    li {
      padding-left: .5rem;
    }
  }
}
;@import "sass-embedded-legacy-load-done:2113";