.page-history,
.page-order-detail {

  .order__actions {
    a {
      display: inline-block;
      padding: 0.5rem;
      color: var(--bs-body-color);

      &::after {
        display: block;
        width: 100%;
        height: 1px;
        content: "";
        background: currentcolor;
        transition: 0.25s ease-out;
      }

      &:hover {
        &::after {
          color: transparent;
        }
      }
    }
  }

  .orders {
    padding: 0.5rem;

    .order {
      padding: 0 1rem;
      background: lighten($gray-100, 2%);
      border-radius: 0.5rem;

      &__label {
        font-weight: 600;
      }

      &__label,
      &__value {
        margin-bottom: 0;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;

        &:not(:last-child) {
          border-bottom: 1px solid $gray-200;
        }

        &.order__actions {
          justify-content: flex-end;
        }
      }
    }
  }
}

.order {
  &__header {
    &__right {
      text-align: right;

      .btn {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }

  &__item {
    &__header {
      padding: 1rem;
    }

    &__line {
      padding: 0.5rem 0;
    }
  }

  &__total {
    padding: 0.5rem 0;
  }
}

;@import "sass-embedded-legacy-load-done:2222";