.table-wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;

  table {
    max-width: 100%;
    border: 1px solid $gray-500;

    > :not(:first-child) {
      border-top: none;
    }

    tbody {
      th,
      td {
        font-weight: 400;
        color: var(--bs-body-color);
        vertical-align: middle;
        box-shadow: none;
      }

      tr {
        &:last-child {
          &,
          td,
          th {
            border-bottom-width: 0;
          }
        }
      }
    }
  }

  .order-products,
  .order-return {
    tfoot {
      td {
        border-bottom-width: 0;
      }

      tr {
        &:first-child {
          td {
            border-top: 1px solid $gray-500;
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2139";