.blockreassurance_product {
  div {
    width: auto;
    margin-bottom: 1.25rem;
    @media screen and (max-width:991px) {
      margin-bottom: .75rem;
    }

    &:nth-of-type(4) {
      width: 100%;
      span, p {
        font-size: 1.125rem;
        @media screen and (max-width:991px) {
          font-size: .875rem;
        }
      }
    }

    &:not(:nth-of-type(4)) {
      @media screen and (max-width:1400px) {
        max-width: 7rem;
      }
    }
  }

  img {
    margin-bottom: .75rem;
    @include media-breakpoint-down(md) {
      max-height: 2.5rem;
      margin-bottom: .5rem;
    }
  }

  span,
  p {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.5rem;
    text-align: center;
    font-size: .875rem;
    padding: 0 .25rem;
    text-transform: uppercase;
    @media screen and (min-width:1800px) {
      font-size: 1rem;
    }
    @include media-breakpoint-down(lg) {
      font-size: 0.75rem;
      line-height: 1.5;
    }
  }
}

.page-cart {
  .blockreassurance_product {
    div {
      @media screen and (max-width:1400px) {
        &:not(:nth-of-type(4)) {
          max-width: 6rem;
        }
        img {
          max-height: 2.5rem;
          margin-bottom: .5rem;
        }
        span, p {
          font-size: 0.75rem;
          line-height: 1.2;
        }
      }
    }
  }
}

.reassurance {
  .cb-item {
    position: relative;

    .cb-item-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .custom-html {
      padding-left: 4.75rem;
      @include media-breakpoint-down(xl) {
        padding-left: 3.25rem;
        padding-right: 0.5rem;
      }
      @include media-breakpoint-down(lg) {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
    }

    img {
      display: inline-block;
      height: auto;
      width: 3.75rem;
      @include media-breakpoint-down(xl) {
        width: 2.75rem;
      }
    }
  }

  p {
    font-family: $font-family-highlight;
    margin-bottom: 0;
    line-height: 1;
    @include media-breakpoint-down(lg) {
      text-align: center !important;
    }

    a {
      color: $black;
    }

    &:nth-of-type(1) {
      @include media-breakpoint-up(lg) {
        position: absolute;
        left: 0;
        top: 0;
      }
      @include media-breakpoint-down(xl) {
        font-size: 1.5rem;
      }
    }
    &:nth-of-type(2) {
      margin-bottom: 0.5rem;
      font-size: 1.875rem;
      @include media-breakpoint-down(xl) {
        font-size: 1.5rem;
      }
    }
    &:nth-of-type(3) {
      font-size: 1.125rem;
      @include media-breakpoint-down(xl) {
        font-size: 1rem;
      }
    }
  }
}
;@import "sass-embedded-legacy-load-done:2170";