$component-name: header-block;

.#{$component-name} {
  position: relative;

  &__action-btn {
    --header-block-spacing-x: .375rem;
    --header-block-spacing-y: #{$header-block-spacing-y};

    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: var(--header-block-spacing-y) var(--header-block-spacing-x);
    color: var(--header-top-color);
    border-radius: $btn-border-radius;

    &:hover,
    &:focus {
      color: var(--header-top-color);
    }

    @include media-breakpoint-up(md) {
      --header-block-spacing-x: .375rem;
      --header-block-spacing-y: #{$header-block-spacing-lg-y};
    }
  }

  &__icon {
    @include media-breakpoint-up(md) {
      margin-right: 0.2em;
    }
  }

  &__badge {
    position: absolute;
    top: 0.4em;
    right: 0;
    display: inline-block;
    min-width: 0.825rem;
    height: 0.85rem;
    width: 0.85rem;
    padding: .0625rem;
    font-size: 0.75rem;
    line-height: 1;
    margin-left: 0;
    color: $white;
    font-weight: 400;
    text-align: center;
    background: $primary;
    border-radius: 50rem;
  }

  /* When active, on desktop everything is primary */
  &--active {
    @include media-breakpoint-up(lg) {
      .#{$component-name} {
        &,
        &__icon {
          color: var(--bs-primary);
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2155";