$component-name: step;

.#{$component-name} {
  .address {
    border: 1px solid $gray-300;

    &,
    &__content {
      cursor: pointer;
    }

    &.selected {
      border: 2px solid $primary;
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background: var(--bs-gray-100);
    border-radius: var(--bs-border-radius-sm);

    dd,
    dt {
      width: 50%;
      padding: 1rem 0;
      margin: 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--bs-gray-300);
      }
    }

    dd {
      text-align: right;
    }
  }

  &__account a {
    font-weight: bold;
  }
}

.step__title {
  text-align: center;
  border-bottom: 0.125rem solid $primary;
}
.step__content {
  padding-top: 2rem;
}


.page-order {
  .js-conditions-to-approve a {
    color: $primary;
    text-decoration: underline;
    text-underline-position: under;
  }

  .header-top__center {
    margin: auto;
  }
  .header-top-desktop {
    @media (max-width: 767px) {
      padding: 2.5rem 0;
    }
  }
  .header-top__left,
  .header-top__right,
  .breadcrumb__wrapper,
  .adtm_menu_container,
  .footer .footer__before,
  .footer .footer__main .container-fluid {
    display: none !important;
  }

  .footer {
    margin-top: 4rem;
    .footer__main {
      padding-top: 0;
    }
  }
}


.delivery-option__right,
.delivery-option__center {
  @media (max-width: 575px) {
    padding-left: calc(16% + 15px);
  }
}

.order-options #delivery {
  display: none;
}

#promo-code {
  .btn-secondary {
    color: #fff;
    border-color: $highlight;
    background-color: $highlight;
    &:hover,
    &:active,
    &:focus {
      color: $white;
      border-color: $black;
      background-color: $black;
    }
  }
}

.carrier__extra-content {
  .btn-primary {
    margin-top: 1rem;
    font-size: 0.75rem;
    padding: 0.5rem 1.25rem;
    margin-left: 3rem;
  }
}
;@import "sass-embedded-legacy-load-done:2218";