@use "sass:color";

$default-icon-color: var(--bs-primary) !default;

body,
html {
  min-height: 100%;
}

body {
  font-family: $font-family-base;
  color: var(--bs-body-color);
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  padding-left: 0;
  list-style: none;
  li::marker {
    color: $primary;
  }
}

.text-white {
  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }
}

.text-red {
  color: #FD1616;
}

.text-right {
  text-align: right;
}

a {
  color: $primary;
}

a:not(.alert-link) {
  text-decoration: none;
  transition: 0.25s ease-out;

  .material-icons {
    transition: 0.25s ease-out;
  }
}

.material-icons {
  &.success {
    color: #00C136;
  }
  &.error {
    color: #FD1616;
  }
}


b, strong {
  font-weight: 700;
}


p a {
  color: $primary;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    color: $primary;
    text-decoration: underline;
  }
}

h1 {
  color: $black;
  font-family: $font-family-highlight;
}

h2, .h2,
h3, .h3 {
  font-weight: 700; 
}

p + h2,
p + h3,
p + h4,
p + h5 {
  margin-top: 1.5rem;
}

.h2 .elementor-heading-title {
  font-size: $h2-font-size;
}

.form-check-input {
  border: 1px solid $primary;
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}
.form-check-input:focus {
  border-color: $primary;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);
}
.form-check .form-check-input {
  border-color: $primary;
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  border-width: 4px;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}
/** focus shadow pinkish **/
.custom-checkbox .custom-control-input:focus~.custom-control-label::before{
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 0, 247, 0.25); 
}
.form-text {
  color: #838383;
}

.label-required:after,
.label-required-info:before {
  content: "*";
  color: var(--bs-danger);
}
.form-label .form-text {
  font-style: italic;
  font-weight: normal;
}
.label-required-info {
  text-align: right;
  font-style: italic;
}



label .color,
.variant-links .color,
.custom-checkbox input[type="checkbox"] + span.color {
  display: inline-block;
  width: 1.375rem;
  height: 1.375rem;
  margin: 0.3125rem;
  margin-right: .875rem;
  margin-left: 0;
  cursor: pointer;
  box-sizing: initial;
  border-radius: 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.25s ease-out;
  border: 2px solid transparent;
  box-shadow: 0 0 0 1px #dadada;

  &.color-sm {
    width: 1rem;
    height: 1rem;
  }

  &.active,
  &:hover {
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 0px 1px rgb(0, 0, 0);
  }
}


.input-color {
  display: none;
}

.bright {
  color: var(--bs-gray-100);
}

.dark {
  color: var(--bs-gray-800);
}

.alert-success {
  background-color: $secondary;
}
.alert-warning {
  background-color: $secondary;
  border-color: $primary;
  a {
    text-decoration: underline;
  }
}

.col-with-padding {
  padding-right: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
  padding-left: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
}

.col-with-margin {
  margin-right: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
  margin-left: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
}

.row-fluid>* {
  padding-right: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
  padding-left: calc($grid-gutter-width * .5); // stylelint-disable-line function-disallowed-list
  &:first-child{
    padding-left:0; 
  }
  &:last-child{
    padding-right:0;  
  }
}


#gdpr_consent{
  label{
    font-style: italic;
    font-size: .75rem; 
    font-weight: 300;
    text-decoration: underline;
  } 
}


.table {
  max-width: 58.75rem;
  margin: auto; 
  margin-bottom: 1rem;
  border-color: $gray-500;
  th {
    background-color: $secondary;
  }
  tr {        
    border-color: $gray-500;
  }
  * {
    @include media-breakpoint-down(md) {
      font-size: 0.875rem;
    }
  }
  td, th {
    padding: 1rem 1.5rem;
    @include media-breakpoint-down(md) {
      padding: .5rem .75rem;
    }
    vertical-align:baseline;
    border: .0625rem solid $gray-500;
  } 
  .badge {
    white-space : inherit;
  }
}

.img-responsive{
  @extend .img-fluid;
}

#left-column>div,#right-column>div,#right-column>section {
  margin-bottom: 1rem;
}

.bg-light {
  background-color: #fafafa!important;
}
.bg-beige {
  background-color: $secondary;
}

.form-label {
  font-size: .875rem;
  
  &.required{
    &:after{
      content:'*';
      color : var(--bs-danger);
    } 
  }
}

.form-select {
  padding: .9375rem;
  font-size: .875rem;
  border: .0625rem solid $black;
}

.mb-6 {
  margin-bottom: 5rem !important;
}
.mt-6 {
  margin-top: 5rem !important;
}
.ms-6 {
  margin-left: 5rem !important;
}  
.me-6 {
  margin-right: 5rem !important;
}  
.my-6 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}  

.pb-6 {
  padding-bottom: 5rem !important;
}
.pt-6 {
  padding-top: 5rem !important;
}
.ps-6 {
  padding-left: 5rem !important;
}  
.pe-6 {
  padding-right: 5rem !important;
}  
.py-6 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important;
}  

.mb-7 {
  margin-bottom: 6.5rem !important;
}
.mt-7 {
  margin-top: 6.5rem !important;
}
.ms-7 {
  margin-left: 6.5rem !important;
}
.me-7 {
  margin-right: 6.5rem !important;
}
.my-7 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important;
}

.pb-7 {
  padding-bottom: 6.5rem !important;
}
.pt-7 {
  padding-top: 6.5rem !important;
}
.ps-7 {
  padding-left: 6.5rem !important;
}
.pe-7 {
  padding-right: 6.5rem !important;
}
.py-7 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important;
}

@include media-breakpoint-up(lg) {
  .mt-lg-6 {
    margin-top: 5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 5rem !important;
  }
  .ms-lg-6 {
    margin-left: 5rem !important;
  }
  .me-lg-6 {
    margin-right: 5rem !important;
  }
  .my-lg-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-6 {
    padding-top: 5rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 5rem !important;
  }
  .ps-lg-6 {
    padding-left: 5rem !important;
  }
  .pe-lg-6 {
    padding-right: 5rem !important;
  }
  .py-lg-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .mt-lg-7 {
    margin-top: 6.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 6.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 6.5rem !important;
  }
  .me-lg-7 {
    margin-right: 6.5rem !important;
  }
  .my-lg-7 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 6.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 6.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 6.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 6.5rem !important;
  }
  .py-lg-7 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
}

@media (min-width: 1201px) {
  .container-lg {
    max-width: 1170px;
  }
}
@media (min-width: 1401px) {
  .container-lg {
    max-width: 1320px;
  }
}
@media (min-width: 1601px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

.form-control {
  padding: .625rem 1rem;
  border: 0.0625rem solid $light;

  &::placeholder {
    color: $gray-500;  
  }
}

.bg-video-backdrop:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $primary; 
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0.5;
  object-fit: cover;
  mix-blend-mode: multiply;

  & + .container {
    position: relative;
    z-index: 3;
  }
}

.swiper {
  .product-miniature {
    padding: 0 1rem;
    &:not(:last-of-type) {
      border-right: 1px solid #E0E0E0;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    background-color: #fff;

    &:after {
      font-family: 'Material Icons';
      content: '\e5cb';
      font-size: 2rem;
      color: $body-color;
    }
  }
  .swiper-button-next {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.swiper-wrapper {
  .product-miniature {
    padding: 0 1rem;
    &:not(:last-of-type) {
      border-right: 1px solid #E0E0E0;
    }
  }
}


.swiper-container .swiper-wrapper {
  @media screen and (min-width: 1200px) {
    // transform: none !important;
  }
  figure {
    img {
      width: auto;
      margin-bottom: 1rem;
    }
    figcaption {
      color: $gray-700;
      font-size: 1.125rem;
    }
  }
}


/************************************** ELEMENTOR **************************************/

.elementor-button {
  border-radius: 0;
  background: $primary;
  background-color: $primary;
  @extend .btn-primary;
}

.elementor-toggle-item {
  .elementor-tab-title {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 2.125rem;

    a {
      font-size: 1.25rem;
      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }
    }
  }

  .elementor-tab-content {
    padding: 0 32px;
    td {
      vertical-align: top;
      padding: 27px;
      border-bottom: none;
      @include media-breakpoint-down(md) {
        padding: .5rem;
        font-size: .875rem;
      }
    }
    tr:nth-of-type(2n) {
      background-color: $gray-100;
    }
  }
}

.elementor-pagination-position-outside .swiper-container {
  padding-bottom: 0;
}

.elementor-custom-swiper .swiper-container .swiper-wrapper {
  figure {
    figcaption {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    img {
      height: 130px;
      width: auto;
    }
  }
}


.col-md-auto {
  @include media-breakpoint-up(md) {
    flex: 0 0 auto;
    width: auto;
  }
}


.visible--mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.visible--desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">");
}

.close-right {
  position: absolute;
  right: 0.75rem;
  top: 1.125rem;
  z-index: 2;
}

;@import "sass-embedded-legacy-load-done:2001";