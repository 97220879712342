$component-name: product-miniature;

.#{$component-name} {
  .card {
    height: 100%;
  }

  & &__infos {
    position: relative;
    z-index: 90;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;

    &__top,
    &__bottom {
    }

    &__top {
      height: 100%;
      padding-top: 1.25rem;
    }

    &__bottom {
      margin-top: auto;
      margin-bottom: 0;
      @media (max-width: 767px) {
        .quantity-button input {
          max-width: unset;
        }
      }
    }
  }

  & &__title {
    margin: 0;
  }

  & &__image {
    height: auto;
  }

  & &__quickview {
    display: flex;
    margin: auto;
  }

  & &__regular-price {
    text-decoration: line-through;
  }

  & &__prices {
  }

  & &__price {
    @extend .me-2;
  }
}

;@import "sass-embedded-legacy-load-done:2018";