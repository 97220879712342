$component-name: footer;
$footer-background-color: $body-color !default;
$footer-heading-color: var(--bs-body-color) !default;
$footer-text-color: var(--bs-body-color) !default;

.#{$component-name} {
  --footer-background-color: $body-color;
  // --footer-text-color: #{$footer-text-color};
  // --footer-hover-color: var(--bs-white);
  // --footer-heading-color: #{$footer-heading-color};

  & &__main {
    padding-top: 3rem;
    padding-bottom: 1.25rem;
    background: $body-color;
  }

  & &-top {
    padding-bottom: 2.5rem;
  }

  h2,
  h3,
  .h4,
  .h3,
  .copyright {
    &,
    a,p {
      color: #fff;
    }
  }

  a {
    color: #fff;
  }

  li {
    margin-bottom: 0;
  }

  h3,
  h4,
  .h3,
  .h4 {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;

    &,
    a {
      color: #fff;
    }
  }

  // .scroll-top {
  //   position: fixed;
  //   right: 2.25rem;
  //   bottom: 2.25rem;
  //   z-index: 9;
  //   padding: .25rem;
  //   border: none;
  //   display: none;
  //   background-color: #fff;

  //   i {
  //     color: $primary;
  //     font-size: 2rem;
  //     transform: rotate(90deg);
  //   }

  //   &.show {
  //     display: block;
  //   }
  // }

  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: .875rem;
    border-top: .0625rem solid $primary;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    svg {
      margin-left: .75rem;
      margin-right: .75rem;
    }

    &-elements {
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
    &-divider {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  & &__block {
    &__content {
    }

    @include media-breakpoint-down(md) {
      &__content {
        font-size: 0.875rem;
      }
    }

    &__content-list {
      margin-bottom: 0;

      a {
        display: block;
        padding: 0.125rem 0 0;
        color: #fff;
        transition: 0.25s ease-out;
    
        &:hover {
          color: $secondary;
        }

        &.logout {
          color: #fff;
        }

      }

      @include media-breakpoint-down(md) {
        a {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          padding-left: 0;
        }
      }

    }

    &__content-contact {
      .contact__infos {
        margin-bottom: 1rem;
      }

      .contact__phone,
      .contact__fax {
        margin-bottom: 0.5rem;
      }

      > div:last-child {
        margin-bottom: 0;
      }

      .contact__infos,
      .contact__phone,
      .contact__fax,
      .contact__email {
        display: flex;
        align-items: center;

        i {
          margin-right: 0.5rem;
        }
      }
    }

    &__title {
      font-size: 1.375rem;
      margin-right: 1rem;
      font-family: $font-family-highlight;

      &,
      a {
        color: #fff;
      }
    }

    @include media-breakpoint-up(md) {
      &__title {
        margin-bottom: .75rem;
      }
    }

    @include media-breakpoint-down(md) {
      &:last-child {
        margin-bottom: 2rem;
      }
    }
  }
}

.displayFooter {
  @extend .col-lg-3;
  @extend .col-md-12;

  .cb-item {
    text-align: left;
  }
}


.skeepers_carousel_container {
  margin-bottom: 6rem!important;
}
#skeepers_carousel_modal {
  margin-bottom: 0!important;
}

div.grecaptcha-badge {
  display: none;
}
;@import "sass-embedded-legacy-load-done:2205";