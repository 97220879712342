.PM_ASBlockOutput.PM_ASBlockOutputVertical .card-header,
.PM_ASearchTitle {
  display: block;
  width: 100%;
  color: $white;
  padding: .25rem;
  font-size: 1.5rem;
  text-align: center;
  font-family: Intro Head R;
  text-transform: uppercase;
  background-color: $tertiary;
}
.PM_ASBlockOutput.PM_ASBlockOutputVertical {
  .card-header {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .card-block {
    @include media-breakpoint-up(lg) {
      border: 1px solid #E0E0E0;
    }
  }
  .PM_ASForm {
    border-bottom: none;
    @include media-breakpoint-down(md) {
      border: none;
    }
  }
  .PM_ASCriterionsGroup {
    margin-bottom: 0;
    padding: .75rem 0;
    @include media-breakpoint-up(lg) {
      padding: 1rem;
      border-bottom: 1px solid #E0E0E0;
    }
    @include media-breakpoint-down(md) {
      padding: .375rem 0;
    }
  }
  .PM_ASCriterionsGroupTitle {
    border-bottom: none;
    padding-bottom: .5rem;
    padding-top: 0;
    margin-bottom: 0;
  }
  .PM_ASCriterionsGroupName {
    font-size: 1.125rem;
    font-weight: 700;
    color: $black;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  .PM_ASCriterionCheckbox {
    vertical-align: initial;
  }
  .PM_ASCriterionGroupCheckbox {
    .PM_ASLabelLink {
      font-weight: normal;
      font-size: .875rem;
    }
    .PM_ASLabelCheckboxSelected .PM_ASLabelLink,
    .PM_ASLabelCheckbox .PM_ASLabelLink:hover {
      font-weight: normal;
      color: $primary;
    }
  }
  .PM_ASCritRange {
    height: .75rem;
    margin-left: 5%;
    margin-right: 5%;
  }

  .PM_ASCritRangeValue {
    font-size: .875rem;
  }
}


#module-pm_advancedsearch4-searchresults {
  #products {
    order: 1 !important;
    @extend .col-lg-12;
  }
}

.active-filter-title {
  font-size: 1.125rem;
  font-weight: 700;
  color: $black;  
  margin-bottom: .25rem;
}

.advancedsearch-seo {
  #PM_ASearchResults {
    #products {
      width: 100%;
    }
  }
}
;@import "sass-embedded-legacy-load-done:2197";