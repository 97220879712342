.user-info {
  @include media-breakpoint-down(md) {
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    color: var(--bs-gray-800);
    text-decoration: none;

    @include media-breakpoint-down(md) {
      padding: 0.5rem 0.375rem;
    }

    &::after {
      width: 0.75rem;
      height: 0.75rem;
      margin-left: 0.75rem;
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-position: center;
      border: none;
    }
  }
}

;@import "sass-embedded-legacy-load-done:2055";