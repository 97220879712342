.ls-wp-container.ls-v6 .ls-nav-prev,
.ls-wp-container.ls-v6 .ls-nav-next {
  width: 42px;
  height: 42px;
  margin-top: -21px;
  background-color: #2C2A29;
  border-radius: 0!important;
  z-index: 99;

  &:after {
    margin-top: -9px;
    width: 18px;
    height: 18px;
  }

  &:hover {
    transform: none;
  }
}
;@import "sass-embedded-legacy-load-done:2195";