$component-name: footer;

.#{$component-name} {
  .title {
    &[aria-expanded="true"] {
      .material-icons.add {
        display: none;
      }

      .material-icons.remove {
        display: inline-block;
      }
    }

    &[aria-expanded="false"] {
      .material-icons.add {
        display: inline-block;
      }

      .material-icons.remove {
        display: none;
      }
    }
  }

  .collapse {
    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .copyright {
    flex-wrap: wrap;
    margin-bottom: 0;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    & &__block {
      margin-bottom: 2.5rem;
    }
  }

  @include media-breakpoint-down(md) {
    & &__block {
      // max-width: 16rem;
      margin: 0 auto 1rem;
    }
  }

  & &__block {
    &__toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        color: $primary;
        transition: transform 0.25s ease-in-out;
      }

      &:not(.collapsed) {
        i {
          transform: rotate(180deg);
        }
      }

    }
  }

}

;@import "sass-embedded-legacy-load-done:2076";