.language-selector__wrapper {
  position: relative;
  &:before {
    position: absolute;
    bottom: 0.375rem;
    right: 0;
    z-index: 1;
    font-family: "Material Icons";
    content: "\e5cb";
    font-size: 1rem;
    transform: rotate(-90deg);
  }
}

.js-language-selector {
  border: none;
  font-size: 1.125rem;
  text-transform: uppercase;
  padding: 2.125rem 1rem 0.25rem .5rem;
  background-size: 1.25rem auto;
  background-repeat: no-repeat;
  background-position: 50% .875rem;

  &[data-langue="fr"] {
    background-image: url('../../../../img/l/1.jpg');
  }
  &[data-langue="en"] {
    background-image: url('../../../../img/l/2.jpg');
  }
}

;@import "sass-embedded-legacy-load-done:2193";