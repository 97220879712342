#pagenotfound {
  .page-header {
    padding-top: 3rem;
  }
}


.page-pagenotfound {
  .breadcrumb__wrapper {
    display: none;
  }

  .wrapper-error {
    padding: 0;

    #content-wrapper {
      padding-bottom: 0;
    }
    .page-not-found {
      position: relative;
      min-height: 40vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-image: url(../img/piperit-404.jpg);
      background-size: cover;
      background-position: center right;
      padding: 4rem 1rem;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($black,.4);
        z-index: 0;
      }

      & * {
        position: relative;
        z-index: 1;
        color: #fff;
      }
    }
  }
}
;@import "sass-embedded-legacy-load-done:2084";