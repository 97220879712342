$component-name: search;

.search-result {
  margin: 0.5rem 0;

  &__product {
    margin: 0.25rem 0;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: $border-radius;

    &:hover {
      background: var(--bs-gray-100);
    }
  }

  &__name {
    margin-bottom: 0;
    margin-left: 1rem;
    font-size: 1rem;
    color: var(--bs-gray-800);
  }

  &__image {
    max-width: 4rem;
    height: auto;
    border-radius: 8px;
  }
}

.#{$component-name} {
  &__offcanvas {
    bottom: inherit;
    height: auto;
    border-bottom: 0;

    // stylelint-disable-next-line
    #search_widget {
      margin-bottom: 0;
    }

    .offcanvas-header {
      padding: 1.75rem 1rem;
      background-color: #fff;

      .btn-close {
        width: auto;
        height: auto;
        margin: 0;
        font-size: 0.75rem;
        color: var(--bs-gray-700);
        padding: 1.125rem;
      }
    }
  }

  &__container {
    width: calc(100% - 0.5rem);
  }

  &-widgets {
    position: relative;
    // stylelint-disable-next-line
    overflow: visible !important;

    @include media-breakpoint-down(md) {
      max-width: inherit;
    }

    &__dropdown {
      position: absolute;
      top: calc(100%);
      left: 0;
      z-index: 99;
      width: 100vw;
      max-width: 30rem;
      overflow: hidden;
      background: var(--bs-white);
      border: 1px solid $input-border-color;
      border-radius: var(--bs-border-radius-sm);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 991px) {
        top: calc(100% + 1rem);
        right: inherit;
        left: -1rem;
        max-width: inherit;
        width: 100vw;
        border-radius: 0;
      }

      @include media-breakpoint-down(md) {
        top: calc(100% + 0.25rem);
        right: inherit;
        left: -1rem;
        max-width: inherit;
        border-radius: 0 0 8px 8px;
      }
    }

    &__results {
      max-height: 20rem;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      margin: 0;
      overflow-y: scroll;
    }
  }
}

.header-top {
  .header-bottom {
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}
.header-top,
.#{$component-name}__offcanvas {
  // stylelint-disable-next-line, selector-id-pattern
  #search_widget {
    max-width: 18.5rem;
    width: 100%;
    overflow: visible;

    @media screen and (max-width: 992px) {
      max-width: inherit;
    }
    @include media-breakpoint-down(md) {
      min-width: inherit;
    }

    input {
      height: 2.875rem;
      padding-left: 1rem;
      padding-right: 2.75rem;
      border-radius: 0;
      border: 0.0625rem solid $black;
      background-color: transparent;

      &:focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
      }

      @include media-breakpoint-down(md) {
        font-size: 0.9rem;
      }
    }

    .search-icon {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: 0;
        padding: .75rem;
      }
    }

    .header-block__action-btn.search-icon {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .search {
      right: 1.5rem;
      color: var(--bs-gray-700);
    }

    .clear {
      right: 3.5rem;
      color: var(--bs-gray-700);
    }
  }
}

;@import "sass-embedded-legacy-load-done:2031";