.btn-with-icon {
  display: inline-flex;
  gap: $default-button-icon-gap;
  align-items: center;
  justify-content: center;

  .material-icons {
    font-size: $default-button-icon-size;
  }

  &.btn-sm {
    gap: $small-button-icon-gap;

    .material-icons {
      font-size: $small-button-icon-size;
    }
  }

  &.btn-lg {
    gap: $large-button-icon-gap;

    .material-icons {
      font-size: $large-button-icon-size;
    }
  }
}

.btn-unstyle {
  padding: 0;
  text-align: inherit;
  background-color: transparent;
  border: none;

  &:focus {
    outline: 0;

    .expand-more {
      color: var(--bs-primary);
    }
  }
}

.btn {
  &.btn-sm {
    font-size: .875rem;
    padding: .625rem 1.5rem;
    @include media-breakpoint-down(md) {
      font-size: .75rem;
    }
  }

  &.btn-xs {
    font-size: .625rem;
    padding: .25rem .5rem .125rem;
  }

  .btn-check:focus + &,
  &:focus {
    text-decoration: none;
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow);
    } @else {
      box-shadow: none;
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.show {
    box-shadow:none;

    &:focus {
      text-decoration:none;
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: var(--#{$prefix}btn-active-shadow), var(--#{$prefix}btn-focus-box-shadow);
      } @else {
        box-shadow:none;
      }
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    box-shadow:none;
    cursor: not-allowed;
  }
}

.btn-primary,
.btn-primary-reverse,
.btn-primary-reverse-white,
.btn-secondary,
.btn-tertiary,
.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-tertiary {
  font-weight: 700;
  padding: .6875rem 1.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  @include media-breakpoint-down(md) {
    font-size: .75rem;
  }
}

.btn-primary {
  color: $white;
  background: $primary;
  border-color: $primary;
  svg path {
    fill: $white;
  }
    &:hover,
    &:active,
    &:focus {
    color: $white;
    background: $tertiary;
    border-color: $tertiary;
    svg path {
      fill: $white;
    }
  }

  &-reverse {
    color: $white;
    background: $black;
    border-color: $black;

    &:hover,
    &:active,
    &:focus {
      background: $primary;
      border-color: $primary;
    }
  }

  &-reverse-white {
    color: $white;
    background: $black;
    border-color: $black;

    &:hover,
    &:active,
    &:focus {
      color: $black;
      background: $white;
      border-color: $white;
    }
  }
}

.btn-secondary {
  color: $black;
  background: $white;
  border-color: $black;
  @extend .btn-outline-secondary;

  &:hover,
  &:active,
  &:focus {
    color: $white;
    background: $primary;
    border-color: $primary;
  }
}

.btn-tertiary {
  color: $white;
  background: $black;
  border-color: $black;
  @extend .btn-outline-tertiary;

  &:hover,
  &:active,
  &:focus {
    color: $white;
    background: $primary;
    border-color: $primary;
  }
}

.btn-default {
  @extend .btn-primary; 
}
;@import "sass-embedded-legacy-load-done:2008";