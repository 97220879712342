$component-name: subcategories;

.subcategory {
  position: relative;
  display: flex;
  height: 100%;
  border: none;

  &__link {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    margin-bottom: 1rem;
  }

  &__image {
    flex-shrink: 0;
  }

  &__name {
    margin-bottom: .25rem;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

;@import "sass-embedded-legacy-load-done:2114";