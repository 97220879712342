#module-prestablog-blog {

  // @media screen and (max-width:1400px) {
  //   .container-fluid.col-lg-8 > .flex-column-reverse {
  //     flex-direction: column-reverse !important;
  //   }
  // }

  #content-wrapper {
    padding-bottom: 4rem;
  }

  // .wrapper__left-column.col-lg-4 {
  //   @include media-breakpoint-up(xl) {
  //     width: 20%;
  //   }
  // }
  // .wrapper__content.col-lg-8 {
  //   @include media-breakpoint-up(xl) {
  //     width: 80%;
  //   }
  // }
}

.prestablog {
  background-color: rgba($primary,.1);

  .section-title {
    @extend .dotted;
  }
  .section-subtitle {
    margin-bottom: 1rem;
    line-height: 1.2;
  }
}

.page-index {
  #blog_list_1-7 {
    // @extend .col-md-10;
    // @extend .offset-md-1;
    // float: none;
    // @media screen and (max-width: 1600px) {
    //   width: calc(100% - 6rem);
    //   margin-left: 3rem;    
    // }
    // @include media-breakpoint-down(lg) {
    //   width: calc(100% - 2rem);
    //   margin-left: 1rem;    
    //   .blog-grid:not(:nth-of-type(1),:nth-of-type(2)) {
    //     display: none;
    //   } 
    // }
    // @include media-breakpoint-down(md) {
    //   width: 100%;
    //   margin-left: 0;  
    // }
  }
}

.article-header {
  overflow: hidden;

  .news {
    width: auto;
    height: 100%;
    object-fit: cover;
  }

  .article-header-left {
    @media screen and (max-width: 991px) {
      width: 100%;
      padding: 0;
      text-align: center;
    }
  }

  .article-header-right {
    z-index: 2;
    color: #fff;
    margin-right: 15px;
    background-color: $black;
    padding: 1.875rem 1.875rem 1.25rem;
    @media screen and (max-width: 991px) {
      width: 100%;
      margin-right: 0;
      padding: 1.5rem 1rem 1rem;
    }
    @media screen and (min-width: 992px) {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: auto;
    }
  }

  time.date {
    color: $primary;
    font-size: 1rem;
    font-weight: 700;
    background: none;
    padding: 0;
    margin: 0;
    line-height: 1.2;
    border-left: 1px solid #838383;
    padding-left: 1rem;
    margin-left: 1rem;
    &:before {
      display: none;
    }
  }

  .categorie_blog {
    padding: 0.25rem 0.5rem 0.125rem;
    font-family: Intro Head R;
    font-size: 1.125rem;
    font-weight: 400;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    background-color: $primary;
    margin-bottom: 0.5rem;

    @media screen and (min-width: 992px) {
      font-size: 1.375rem;
    }
  }
  .prestablog_writtenby {
    margin-bottom: 0.5rem;
    font-size: .875rem;
    a {
      color: #fff;
    }
  }

  h1 {
    width: 100%;
    color: #fff;
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: .5rem;

    @media screen and (min-width: 992px) {
      font-size: 2.25rem;
      line-height: 1.2;
    }
  }
  .prestablog_eye,
  .prestablog_comments {
    color: #fff;
    font-size: .75rem;
    font-style: italic;
    margin-top: .25rem;
    margin-bottom: .625rem;  
  }
  .prestablog_desc {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

#module-prestablog-blog {
  // .container-fluid.col-lg-10,
  // .container-fluid.col-lg-8 {
  //   width: 100%;
  //   margin-left: 0;
  //   @media screen and (min-width: 1401px) {
  //     width: 83.33333333%;
  //     margin-left: 8.33333333%;
  //     padding: 0;
  //   }
  //   @media screen and (min-width: 1601px) {
  //     margin-left: 16.66666667%;
  //     width: 66.66666667%;
  //   }
  // }

  // .breadcrumb__wrapper .container-fluid {
  //   @media screen and (min-width: 1401px) {
  //     width: 83.33333333%;
  //     margin-left: 8.33333333%;
  //     padding: 0;
  //   }
  //   @media screen and (min-width: 1601px) {
  //     margin-left: 16.66666667%;
  //     width: 66.66666667%;
  //   }
  // }

  .wrapper__left-column {
    width: 100%;
    @media screen and (min-width: 1401px) {
      width: 16rem;
      padding-left: 0;
    }
    @media screen and (max-width: 1400px) {
      padding-top: 2.5rem;
      margin-bottom: 2rem;
      border-top: 1px solid #E0E0E0;
    }
  }

  .wrapper__content {
    width: 100%;
    @media screen and (min-width: 1401px) {
      width: calc(100% - 16rem);
      padding-right: 0;
    }
  }
}


.swiper.swiper-last-blog {
  max-width: 16rem;
  margin-left: 0;
  padding-bottom: 1rem;

  .swiper-pagination-last-blog {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
    &:nth-of-type(2),
    &:nth-of-type(4) {
      width: 7px;
      height: 7px;
    }
    &:nth-of-type(1),
    &:nth-of-type(5) {
      width: 5px;
      height: 5px;
    }
  }
}

.prestablog_article {
  margin-bottom: 2.5rem;
}

.prestablog-article-extras {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  margin-bottom: 0;
  border-top: 1px solid #E0E0E0;

  #blog_list_1-7 {
    width: auto;
    float: none;
    padding: 0;
    display: flex;
  }
}

.comment[data-author="La Pipe rit"] {
  padding-left: 4rem;
  border-bottom: none;
  background-color: $secondary;
}

.soc-buttons{box-sizing:border-box;display:flex;flex-wrap:wrap;font-size:1rem;height:36px;margin:0;padding:0;width:100%}
.soc-buttons:after{clear:both}
.soc-buttons:after,.soc-buttons:before{content:' ';display:table}
.soc-buttons li{box-sizing:border-box;height:100%;line-height:13px;list-style:none;margin-right: 30px;margin-bottom: 6px;}
.soc-buttons li.soc-email a{background-color:#0a88ff}
.soc-buttons li.soc-email a:hover{background-color:#006ed6}
.soc-buttons li.soc-facebook a{background-color:#306199}
.soc-buttons li.soc-facebook a:hover{background-color:#244872}
.soc-buttons li.soc-linkedin a{background-color:#007bb6}
.soc-buttons li.soc-linkedin a:hover{background-color:#005983}
.soc-buttons li.soc-twitter a{background-color:#26c4f1}
.soc-buttons li.soc-twitter a:hover{background-color:#0eaad6}
.soc-buttons li.soc-youtube a{background-color:#df1c31}
.soc-buttons li.soc-youtube a:hover{background-color:#b21627}
.soc-buttons li.soc-pinterest a{background-color:#b81621}
.soc-buttons li.soc-pinterest a:hover{background-color:#8a1119}
.soc-buttons li.soc-instagram a{background-color:#125688}
.soc-buttons li.soc-instagram a:hover{background-color:#0c3a5b}
.soc-buttons li.soc-print a{background-color:#8d98a2}
.soc-buttons li.soc-print a:hover{background-color:#717f8b}
.soc-buttons li a{display:flex;align-items:center;padding:16px 25px;background-color:#ccc;border-radius:40px;font-size: 16px;box-sizing:border-box;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;font-weight:700;height:100%;padding:16px 16px;position:relative;text-decoration:none;-webkit-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out;width:100%}
.soc-buttons li a .soc-icon{display:block;margin-right: 4px;}
.soc-buttons li a .soc-icon svg{height:16px;width:16px}
.soc-buttons li a .soc-icon svg circle,.soc-buttons li a .soc-icon svg path{fill:#fff}
.soc-buttons li a .soc-text{color:#fff}
.soc-buttons li a:active{box-shadow:inset 1px 3px 15px 0 rgba(22,0,0,.25)}
.soc-buttons li.small a{padding:0}
.soc-buttons li.small a .soc-icon{left:auto;margin:0 auto;overflow:hidden;position:relative;top:auto;width:100%}
.soc-buttons li.small a .soc-text{visibility:hidden}
.soc-buttons.large-format,.soc-buttons.large-format li{height:auto}
.soc-buttons.large-format li a{-webkit-backface-visibility:hidden;backface-visibility:hidden}
.soc-buttons.large-format li a .soc-icon{height:100%;left:30px;padding-top:0;}
.soc-buttons.large-format li a .soc-icon svg{}
.soc-buttons.large-format li a .soc-text{-webkit-backface-visibility:hidden;backface-visibility:hidden}
.soc-buttons.small-format{padding-top:5px}
.soc-buttons.small-format li{height:80%;padding:0 1px}
.soc-buttons.small-format li a .soc-icon{height:100%;padding-top:0}
.soc-buttons.small-format li a .soc-icon svg{height:48%;position:relative;top:6px;width:80%}
.soc-buttons.tiny-format{height:22px;position:relative}
.soc-buttons.tiny-format li{padding-right:7px}
.soc-buttons.tiny-format li a{background-color:transparent;padding:0}
.soc-buttons.tiny-format li a .soc-icon{height:100%}
.soc-buttons.tiny-format li a .soc-icon svg{height:70%;width:100%}
.soc-buttons.tiny-format li a:active,.soc-buttons.tiny-format li a:hover{background-color:transparent}
.soc-buttons.tiny-format li.soc-email a .soc-icon svg path{fill:#0a88ff}
.soc-buttons.tiny-format li.soc-email a .soc-icon:hover .soc-icon svg path{fill:#0054a3}
.soc-buttons.tiny-format li.soc-facebook a .soc-icon svg path{fill:#306199}
.soc-buttons.tiny-format li.soc-facebook a .soc-icon:hover .soc-icon svg path{fill:#18304b}
.soc-buttons.tiny-format li.soc-linkedin a .soc-icon svg path{fill:#007bb6}
.soc-buttons.tiny-format li.soc-linkedin a .soc-icon:hover .soc-icon svg path{fill:#003650}
.soc-buttons.tiny-format li.soc-twitter a .soc-icon svg path{fill:#26c4f1}
.soc-buttons.tiny-format li.soc-twitter a .soc-icon:hover .soc-icon svg path{fill:#0b84a6}
.soc-buttons.tiny-format li.soc-youtube a .soc-icon svg path{fill:#df1c31}
.soc-buttons.tiny-format li.soc-youtube a .soc-icon:hover .soc-icon svg path{fill:#84111d}
.soc-buttons.tiny-format li.soc-pinterest a .soc-icon svg path{fill:#b81621}
.soc-buttons.tiny-format li.soc-pinterest a .soc-icon:hover .soc-icon svg path{fill:#5d0b11}
.soc-buttons.tiny-format li.soc-instagram a .soc-icon svg path{fill:#125688}
.soc-buttons.tiny-format li.soc-instagram a .soc-icon:hover .soc-icon svg path{fill:#061d2e}
.soc-buttons.tiny-format li.soc-whatsapp a .soc-icon svg path{fill:#43d854}
.soc-buttons.tiny-format li.soc-whatsapp a .soc-icon:hover .soc-icon svg path{fill:#1f962d}
.soc-buttons.tiny-format li.soc-print a .soc-icon svg path{fill:#8d98a2}
.soc-buttons.tiny-format li.soc-print a .soc-icon:hover .soc-icon svg path{fill:#5a656f}
.soc-buttons.tiny-format li.soc-xing a .soc-icon svg path{fill:#005A5F}
.soc-buttons.tiny-format li.soc-xing a .soc-icon:hover .soc-icon svg path{fill:#000}

@media (max-width: 767px) {
  .soc-buttons li a {
    font-size: .75rem;
  }
  .soc-buttons li {
    margin-right: .75rem;
  }
}

.price_product_blog {
  color: $black;
  text-align: left;
  font-weight: 600;
  font-size: 1.125rem;
}

@media screen and (min-width: 1401px) {
  .swiper-prestablog-products.swiper,
  .swiper-prestablog-articles.swiper {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}
@media screen and (max-width: 1400px) {
  #prestablog_list {
    display: none;
  }
  #prestablog_catliste li {
    display: inline-block;
    margin-right: .75rem;
    margin-bottom: .25rem;
  }
}
;@import "sass-embedded-legacy-load-done:2191";