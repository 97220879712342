.social-sharing {
  display: flex;
  align-items: center;

  > span {
    display: inline-block;
    margin-right: 0.5rem;
  }

  ul {
    display: flex;
    align-items: center;
    margin: 0;

    li {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      background-position: center;
      background-size: contain;

      &::before {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
      }
    }
  }
}


.social-share-btn {
  display: block;
  margin-right: .875rem;

  &:not(use) > svg {
    fill: #fff;
    width: 2.5rem;
    height: 2.5rem;
    padding: .125rem;
    border-radius: 50%;
    background-color: $primary;

    @include media-breakpoint-down(md) {
      width: 2rem;
      height: 2rem;
    }
  }

  &.facebook,
  &.youtube,
  &.pinterest,
  &.instagram {
    margin-right: .625rem;
    @include media-breakpoint-down(md) {
      margin-right: .875rem;
    }
  }

  &:hover {
    svg {
      fill:#fff;
      background-color: $tertiary;
    } 
  }
}

.social-share-btn--footer {
  transition: background-color 0.3s ease-in-out;
}
;@import "sass-embedded-legacy-load-done:2173";